import React, { createContext, useEffect, useRef, useState } from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from "walletlink"
import Web3Modal from 'web3modal'
import Web3 from 'web3'

export const Web3Context = createContext(null)

export const Web3Provider = ({ children }) => {

	const userWantsConnection = "userWantsWeb3Connection"

    const [account, setAccount] = useState<string>(null)
	const [chainId, setChainId] = useState<number|null>(null)
	const [provider, _setProvider] = useState(null)
	const [web3Modal, setWeb3Modal] = useState<Web3Modal>(null)
	const [web3, _setWeb3] = useState<Web3>(null)
	const [web3Available, setWeb3Available] = useState(false)
	const web3Ref = useRef<Web3>(null)

	const setWeb3 = async (_web3: Web3) => {
		web3Ref.current = _web3
		if (_web3 !== null && _web3.eth !== null && _web3.eth !== undefined) {
			setChainId(await _web3.eth.getChainId())
			let accounts = await _web3.eth.getAccounts()
			let _account = accounts.length == 0 ? null : accounts[0]
			// console.log(` - setting default web3.eth account: ${_account}`)
			_web3.eth.defaultAccount = _account
			setAccount(_account)
		} else {
			if (_web3 !== null) {
				console.warn(` - web3.eth is ${_web3.eth}`)
			}
			setChainId(null)
			setAccount(null)
		}
		_setWeb3(_web3)
	}

	const setProvider = async (_provider: any) => {
		_setProvider(_provider)
		if (_provider === null) {
			console.log(`Clearing cached provider: ${web3Modal}`)
			localStorage.removeItem(userWantsConnection)
			web3Modal?.clearCachedProvider()
		}
		await setWeb3(_provider === null ? null : new Web3(_provider))
	}

	/// Ensures a connection to the user's MetaMask wallet -- needs connection to UI if there are failures
	async function connectToWeb3() {
		console.log(`Opening a dialog ${web3Modal !== null}`)
		localStorage.setItem(userWantsConnection, `true`)
		await connectToWeb3Modal(web3Modal)
	}

	/// Ensures a connection to the user's MetaMask wallet -- needs connection to UI if there are failures
	async function connectToWeb3Modal(modal: Web3Modal) {
		try {
			let _provider = await modal.connect()
			if (_provider.on) {
				_provider.on("accountsChanged", handleAccountsChanged)
				_provider.on("chainChanged", handleChainChanged)
				_provider.on("connect", handleConnect)
				_provider.on("disconnect", handleDisconnect)
			}
			await setProvider(_provider)
		} catch(error) {
			if (error.code == 4001 || error.code == 4100) {
				// EIP-1193 userRejectedRequest or unauthorized error
				console.log(`Permissions needed to continue: ${error.code}`)
			} else {
				console.error(`Could not get a wallet connection(${error.code}): ${error}`)
			}
			await setProvider(null)
		}
	}

	async function disconnectFromWeb3() {
		var disconnect = window.confirm(`Disconnect wallet?`)
		if (disconnect) {
			console.log(`Clearing connection configuration: ${provider}`)
			if (provider.removeListener) {
				console.log(` - removing event listeners`)
				await provider.removeListener("accountsChanged", handleAccountsChanged)
				await provider.removeListener("chainChanged", handleChainChanged)
				await provider.removeListener("connect", handleConnect)
				await provider.removeListener("disconnect", handleDisconnect)
			}
			if (provider.close) {
				console.log(` - closing via provider`)
				await provider.close()
			}
			if (provider.disconnect) {
				console.log(` - disconnecting via provider`)
				await provider.disconnect()
			}
			await setProvider(null)
			console.log(`All local configuration cleared`)
		}
	}

	/// Rebuilds UI if a user changes the account in their MetaMask
	function handleAccountsChanged(accounts: string[]) {
		console.log(`accounts changed: ${accounts}`)
		let _account = accounts.length == 0 ? null : accounts[0]
		var _web3 = web3Ref.current
		if (_web3 !== null && _web3 !== undefined && _web3.eth !== null && _web3.eth !== undefined) {
			// console.log(` - setting default account`)
			_web3.eth.defaultAccount = _account
		} else {
			console.warn(` - did not set default account`)
		}
		if (_account === null) {
			setProvider(null)
		} else {
			setAccount(_account)
		}
	}

	function handleChainChanged(chainId: number) {
		// console.log(`Chain changed to ${chainId}`)
		setChainId(chainId)
	}

	function handleConnect(info: { chainId: number }) {
		console.log(`Connect: ${info}`)
		setChainId(info.chainId)
	}

	function handleDisconnect(error: { code: number; message: string }) {
		console.log(`disconnect(${provider}): ${error}`)
		setProvider(null)
	}

	function getProviderOptions() {
		return {
			walletconnect: {
				package: WalletConnectProvider,
				options: { infuraId: "105130ee80d44088a76a6d0cb6db83a3" }
			},
			walletlink: {
				package: WalletLink,
				options: {
					appName: "The App Studio LLC Site Test",
					infuraId: "105130ee80d44088a76a6d0cb6db83a3"
				}
			}
		}
	}

	useEffect(() => {
		const setupWeb3 = async () => {
			// TODO: Add a property that will disable web3 functionality
			if (location.protocol !== 'https:') {
				// https://ethereum.stackexchange.com/a/62217/620
				console.warn(`HTTPS connection required for Web3`)
				// return
			}
			console.log(`Initializing web3...`)
			const providerOptions = getProviderOptions()
			var modal = new Web3Modal({
				cacheProvider: true, // optional
				providerOptions, // required
				disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
			})
			setWeb3Modal(modal)
			if (localStorage.getItem(userWantsConnection) == `true`) {
				await connectToWeb3Modal(modal)
			}
			setWeb3Available(modal !== null)
		}
		if (web3Modal === null) {
			setupWeb3()
		}
	}, [web3Available])

	return (
		<Web3Context.Provider value={{account, chainId, connectToWeb3, disconnectFromWeb3, web3, web3Available}}>
			{children}
		</Web3Context.Provider>
	)
}
